import React, { useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { filterContainerBoarder } from 'lib/css/colors';
import { areYouSure } from 'lib/utils/toast';
import {
  ALL_PRODUCT_LISTS,
  BRANDS_FOR_DROPDOWN,
  GLOBAL_PRODUCT_VENDOR_HIDE_SUGGESTIONS,
  GLOBAL_PRODUCT_VENDOR_LINK_SUGGESTIONS,
  GLOBAL_PRODUCT_VENDOR_MERGE_ML_SUGGESTED,
} from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductLinksSuggestionsTableRow from 'components/global_products/global_products_view/ProductLinksSuggestionsTableRow';
import usePost from 'lib/hooks/usePost';
import useGet from 'lib/hooks/useGet';

function ProductsLinksSuggestionsView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedProductList, setSelectedProductList] = useState(null);
  const [selectedMfrGlobalProductIds, setSelectedMfrGlobalProductIds] = useState([]);
  const [selectedSuggestionsIds, setSelectedSuggestionsIds] = useState([]);

  const { data: { data } = [], brandsLoading } = useGet(BRANDS_FOR_DROPDOWN, {
    search: '',
    verified_only: true,
    product_list_id: selectedProductList,
  });

  const { data: { product_lists } = [], productListsLoading } = useGet(ALL_PRODUCT_LISTS, {});

  const {
    data: { suggestions, count } = {},
    loading: loading,
    refetch: refetch,
  } = useGet(GLOBAL_PRODUCT_VENDOR_LINK_SUGGESTIONS, {
    brand_id: selectedBrand,
    product_list_id: selectedProductList,
    offset: currentPage - 1,
  });

  const { postData: linkProducts } = usePost(
    GLOBAL_PRODUCT_VENDOR_MERGE_ML_SUGGESTED,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected products have been linked successfully',
        timer: 600,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const { postData: hideSuggestions } = usePost(
    GLOBAL_PRODUCT_VENDOR_HIDE_SUGGESTIONS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'The selected suggestions have been hidden successfully',
        timer: 600,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred : ${error?.response?.data?.message}`,
      }),
  );

  const brandOptions = [...(data?.map(brand => ({ label: brand.name, value: brand.id })) || [])];
  const productListsOptions = [
    ...(product_lists?.map(productList => ({ label: productList.name, value: productList.id })) ||
      []),
  ];

  const handleLinkProducts = () => {
    areYouSure(
      () =>
        linkProducts({
          mfr_global_product_ids: selectedMfrGlobalProductIds,
          vendor_product_id: suggestions[0].vendor_product.id,
          suggestion_ids: selectedSuggestionsIds,
        }),
      'Are you sure you want to link the selected products?',
      'Link Products',
    );
  };

  const handleHideSuggestions = () => {
    areYouSure(
      () =>
        hideSuggestions({
          ids_to_hide: selectedSuggestionsIds,
        }),
      'Are you sure you want to hide the selected suggestions?',
      'Hide suggestions',
    );
  };
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-left">
        <h3 className="font-weight-bold mr-2">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => {
              history.push('/enrich-products/manage-brands');
            }}
          />
          Suggestive Product Links
        </h3>
      </div>
      <Row>
        <Form.Group as={Col} md={2}>
          <Form.Label>Product List</Form.Label>
          <Select
            value={productListsOptions?.find(option => option.value === selectedProductList)}
            options={productListsOptions}
            onChange={option => {
              setSelectedProductList(option ? option.value : null);
              setCurrentPage(1);
            }}
            isLoading={productListsLoading}
            isClearable
          />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>Brand</Form.Label>
          <Select
            value={brandOptions?.find(option => option.value === selectedBrand)}
            options={brandOptions}
            onChange={option => {
              setSelectedBrand(option ? option.value : null);
              setCurrentPage(1);
            }}
            isLoading={brandsLoading}
            isClearable
            isDisabled={!selectedProductList}
          />
        </Form.Group>
      </Row>
      {loading ? (
        <LoadingSpinner />
      ) : suggestions && suggestions.length > 0 ? (
        <>
          <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
            <Row>
              <Col>
                <Button
                  className={css(styles.button)}
                  variant="link"
                  size="lg"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  &lt; Previous
                </Button>
                <Button
                  className={css(styles.button)}
                  variant="link"
                  size="lg"
                  disabled={currentPage === count}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next &gt;
                </Button>
                <span>{currentPage + '/' + count}</span>
              </Col>
              <Col className="d-flex justify-content-end align-content-center">
                <Button
                  className={css(styles.linkButton)}
                  variant="primary"
                  size="lg"
                  onClick={handleLinkProducts}
                >
                  Link products
                </Button>
                <Button
                  className={css(styles.hideSuggestionButton)}
                  variant="outline-primary"
                  size="lg"
                  onClick={handleHideSuggestions}
                >
                  Hide suggestion
                </Button>
              </Col>
            </Row>
            <Row>
              <Table className="ml-4 mr-4" striped bordered hover>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Manufacturer</th>
                    <th>Pack Size</th>
                  </tr>
                </thead>
                <tbody>
                  {suggestions && suggestions.length > 0 ? (
                    <tr>
                      <td>{suggestions[0].vendor_product.vendor_sku}</td>
                      <td>{suggestions[0].vendor_product.name}</td>
                      <td>{suggestions[0].vendor_product.brand.name}</td>
                      <td>{suggestions[0].vendor_product.manufacturer_name}</td>
                      <td>{suggestions[0].vendor_product.pack_size}</td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Row>
          </Col>
          <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
            <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
              Possible Matches
            </h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Thumbnail</th>
                  <th>Manufacturer</th>
                  <th>Manufacturer product code</th>
                  <th>Brand Name</th>
                  <th>Pack size</th>
                  <th>GTIN</th>
                  <th>Similarity score</th>
                </tr>
              </thead>
              <tbody>
                {suggestions &&
                  suggestions.map(suggestion => (
                    <ProductLinksSuggestionsTableRow
                      key={suggestion.manufacturer_product.glin}
                      suggestion={suggestion}
                      selectedMfrGlobalProductIds={selectedMfrGlobalProductIds}
                      setSelectedMfrGlobalProductIds={setSelectedMfrGlobalProductIds}
                      selectedSuggestionsIds={selectedSuggestionsIds}
                      setSelectedSuggestionsIds={setSelectedSuggestionsIds}
                    />
                  ))}
              </tbody>
            </Table>
          </Col>
        </>
      ) : (
        'No products to display'
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
    marginLeft: '1px',
    marginRight: '1px',
    width: 'auto',
    minWidth: '100px',
    height: 'auto',
    minHeight: '70px',
    overflow: 'visible',
    position: 'relative',
  },
  button: {
    fontSize: '1.2em',
    padding: '0.5em',
    fontWeight: 'bold',
  },
  linkButton: {
    width: '100px',
    maxHeight: '30px',
    marginLeft: '4px',
  },
  hideSuggestionButton: {
    width: '150px',
    maxHeight: '30px',
    marginLeft: '4px',
  },
});

export default ProductsLinksSuggestionsView;

import React from 'react';
import PropTypes from 'prop-types';

import { Form, Image, OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';

import pointerOnHover from 'lib/css/pointerOnHover';

function ProductLinksSuggestionsTableRow({
  suggestion,
  selectedMfrGlobalProductIds,
  setSelectedMfrGlobalProductIds,
  setSelectedSuggestionsIds,
}) {
  const handleSuggestionsCheck = e => {
    const productId = suggestion.manufacturer_product.global_product.id;
    const suggestionId = suggestion.id;
    if (e.target.checked) {
      setSelectedMfrGlobalProductIds(prev => [...prev, productId]);
      setSelectedSuggestionsIds(prev => [...prev, suggestionId]);
    } else {
      setSelectedMfrGlobalProductIds(prev => prev.filter(id => id !== productId));
      setSelectedSuggestionsIds(prev => prev.filter(id => id !== productId));
    }
  };
  return (
    <tr className={classNames(pointerOnHover)}>
      <td>
        <Form.Check
          type="checkbox"
          checked={selectedMfrGlobalProductIds.includes(
            suggestion.manufacturer_product.global_product.id,
          )}
          onChange={handleSuggestionsCheck}
        />
      </td>
      <td>{suggestion.manufacturer_product.name}</td>
      <td>
        <>
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover id="popover-basic" style={{ maxWidth: 400 }}>
                <Popover.Content>
                  <Image
                    width={400}
                    fluid
                    src={suggestion.manufacturer_product.propagated_thumbnail_image_url}
                  />
                </Popover.Content>
              </Popover>
            }
          >
            <Image
              width={50}
              fluid
              src={suggestion.manufacturer_product.propagated_thumbnail_image_url}
              thumbnail
            />
          </OverlayTrigger>
        </>
      </td>
      <td>{suggestion.manufacturer_product.global_manufacturer.name}</td>
      <td>{suggestion.manufacturer_product.manufacturer_sku}</td>
      <td>{suggestion.manufacturer_product.brand.name}</td>
      <td>{suggestion.manufacturer_product.pack_size}</td>
      <td>{suggestion.manufacturer_product.gtin}</td>
      <td>
        <strong>{Math.round(suggestion.similarity_score * 100)}%</strong>
      </td>
    </tr>
  );
}

ProductLinksSuggestionsTableRow.propTypes = {
  suggestion: PropTypes.object,
  selectedMfrGlobalProductIds: PropTypes.array,
  setSelectedMfrGlobalProductIds: PropTypes.func,
  selectedSuggestionsIds: PropTypes.array,
  setSelectedSuggestionsIds: PropTypes.func,
};

export default ProductLinksSuggestionsTableRow;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const isStagingServer = Boolean(
  window.location.hostname.match(/d2l9lkx0uhegw0/) || window.location.hostname.match(/staging/),
);

const isSandboxServer = Boolean(
  window.location.hostname.match(/d2l9lkx0uhegw0/) || window.location.hostname.match(/sandbox/),
);

const isProd = Boolean(window.location.hostname.match(/fsa-categorizer.cutanddry.com/));

const SERVER = isStagingServer
  ? 'https://fsa-categorizer-staging-api.cutanddry.com'
  : isSandboxServer
  ? 'https://fsa-sandbox-api.cutanddry.com'
  : isLocalhost
  ? 'http://127.0.0.1:5000'
  : 'https://fsa-categorizer-api.cutanddry.com';

// for now it would be the same servers for task execution too
const TASK_SERVER = isStagingServer
  ? 'https://fsa-categorizer-staging-api.cutanddry.com'
  : isLocalhost
  ? 'http://127.0.0.1:5000'
  : 'https://fsa-categorizer-api.cutanddry.com';

const API = SERVER + '/api/v1';
const TASK_API = TASK_SERVER + '/api/v1';

// endpoints for the tasks execution
const DATA_SOURCE_PROCESS = TASK_API + '/data-source/process';

const TASK = TASK_API + '/task';
const DELETE_TASK = TASK + '/:task_id';
const KILL_TASK = TASK + '/kill_process/:task_id';
const EXECUTE_TASK = TASK + '/execute-task';

// endpoints for non tasks
const AUTH = API + '/auth';
const LOGIN = AUTH + '/login';
const LOGOUT = AUTH + '/logout';
const CURRENT_USER = AUTH + '/logged-in-user';

const VIEWS = API + '/views';

const API_INTEGRATION = API + '/api-integration';
const API_INTEGRATION_DELETE = API_INTEGRATION + '/:api_integration_id';

const DATA_SYNC = API + '/data-sync';
const EXPORT_ATTRIBUTES = DATA_SYNC + '/export/attributes';
const IMPORT_ATTRIBUTES = DATA_SYNC + '/import/attributes';
const UPDATE_ATTRIBUTES_BY_ATTRIBUTE_ID = DATA_SYNC + '/update-by-attribute-id/attributes';
const IMPORT_ATTRIBUTE_VALUES = DATA_SYNC + '/import/attribute-values';
const EXPORT_PRODUCTS_CD = DATA_SYNC + '/products/export/cut-dry';
const GTIN_VALIDATOR_CSV = DATA_SYNC + '/gtin_validator';
const GLOBAL_PRODUCTS_MARK_SPECIALLY_UPLOADED =
  DATA_SYNC + '/global-products/mark-specially-uploaded';
const EXPORT_P0_CD = DATA_SYNC + '/export/p0-data/cut-dry';
const UPDATE_SYSCO_STATUSES = DATA_SYNC + '/global-products/update-sysco-statuses';
const EXPORT_DATA_CUT_DRY = DATA_SYNC + '/export/cut-dry';
const FIX_VENDOR_DATA = DATA_SYNC + '/fix-vendor-data';
const FIX_MANUFACTURER_DATA = DATA_SYNC + '/fix-manufacturer-data';
const CLEAN_INVALID_PRODUCTS = DATA_SYNC + '/clean-invalid-products';
const EXPORT_HILLCREST_ERRORS = DATA_SYNC + '/export-hillcrest-errors';
const SEPARATE_HILLCREST_PRODUCTS = DATA_SYNC + '/separate-hillcrest-products';
const DELETE_ORPHANED_GLOBAL_PRODUCTS = DATA_SYNC + '/global-products/delete-orphaned';
const FIX_INVALID_GLOBAL_PRODUCT_NAMES = DATA_SYNC + '/global-products/fix-invalid-names';
const HILLCREST_PRODUCT_RESTORE = DATA_SYNC + '/hillcrest-product-restore';
const ADD_TAXONOMY_FRIENDLY_NAMES = DATA_SYNC + '/taxonomy/add-friendly-names';
const RESET_PRODUCT_THUMBNAILS = DATA_SYNC + '/products/reset-thumbnails';
const FSA_SKU_MAPPINGS_EXPORT = DATA_SYNC + '/export/fsa-sku-mappings';
const ADD_VENDOR_THUMBNAIL_ATTRIBUTE = DATA_SYNC + '/global-attributes/vendor-thumbnail';
const ADD_MANUFACTURER_THUMBNAIL_ATTRIBUTE = DATA_SYNC + '/global-attributes/mfr-thumbnail';
const UPDATE_VENDOR_PRODUCT_SCORES = DATA_SYNC + '/global-product-vendors/update-product-scores';
const UPDATE_MANUFACTURER_PRODUCT_SCORES =
  DATA_SYNC + '/global-product-manufacturers/update-product-scores';
const EXPORT_INCORRECTLY_LINKED_PRODUCTS_LIST = DATA_SYNC + '/export/incorrectly-linked-gtins';

const CATEGORIES = API + '/categories';
const CATEGORIES_WITH_PENDING_COUNTS = CATEGORIES + '/pending-counts';
const L1_CATEGORY_OPTIONS = CATEGORIES + '/l1';
const L2_CATEGORY_OPTIONS = CATEGORIES + '/l2';
const L3_CATEGORY_OPTIONS = CATEGORIES + '/l3';
const L4_CATEGORY_OPTIONS = CATEGORIES + '/l4';
const CATEGORY_OPTIONS = CATEGORIES + '/options';

const CATEGORY = API + '/category';
const CATEGORY_DETAILS = CATEGORY + '/:category';
const CATEGORY_EDIT_NAME = CATEGORY + '/edit-name';

const GLOBAL_PRODUCTS = API + '/global-products';
const GLOBAL_PRODUCTS_DOWNLOAD_CSV = GLOBAL_PRODUCTS + '/download-csv';
const GLOBAL_PRODUCTS_BY_IDS = GLOBAL_PRODUCTS + '/get-by-ids';
const GLOBAL_PRODUCTS_BY_SEARCH = GLOBAL_PRODUCTS + '/search';
const GLOBAL_PRODUCTS_UPDATE_CATEGORIES = GLOBAL_PRODUCTS + '/update-categories';
const GLOBAL_PRODUCT_REMOVE_CATEGORIES = GLOBAL_PRODUCTS + '/remove-categories';
const PRODUCTS_WITHOUT_IMAGES = GLOBAL_PRODUCTS + '/products/get-without-images';
const PRODUCTS_BY_GLOBAL_PRODUCT_IDS = GLOBAL_PRODUCTS + '/get-by-global-product-ids';

const GLOBAL_PRODUCT_MANUFACTURER = API + '/global-product-manufacturer';
const GLOBAL_PRODUCT_MANUFACTURER_PDP = GLOBAL_PRODUCT_MANUFACTURER + '/pdp/:productId';
const GLOBAL_PRODUCT_MANUFACTURER_UPDATE = GLOBAL_PRODUCT_MANUFACTURER + '/update';

const GLOBAL_PRODUCT_MANUFACTURERS = API + '/global-product-manufacturers';
const GLOBAL_PRODUCT_MANUFACTURERS_BY_SEARCH = GLOBAL_PRODUCT_MANUFACTURERS + '/search';

const GLOBAL_PRODUCT = API + '/global-product/:productId';
const GLOBAL_PRODUCT_PDP = API + '/global-product/pdp';
const GLOBAL_PRODUCT_MERGE_DUPLICATES = GLOBAL_PRODUCT + ':productId/merge-duplicates';

const GLOBAL_PRODUCT_VENDOR = API + '/global-product-vendor';
const GLOBAL_PRODUCT_VENDOR_CLONE = GLOBAL_PRODUCT_VENDOR + '/clone';
const GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO = GLOBAL_PRODUCT_VENDOR + '/mark-as-demo-product';
const GLOBAL_PRODUCT_VENDOR_LINK_SUGGESTIONS =
  GLOBAL_PRODUCT_VENDOR + '/get-product-link-suggestions';
const GLOBAL_PRODUCT_VENDOR_MERGE_ML_SUGGESTED =
  GLOBAL_PRODUCT_VENDOR + '/link-suggested-products-from-ml';
const GLOBAL_PRODUCT_VENDOR_HIDE_SUGGESTIONS = GLOBAL_PRODUCT_VENDOR + '/hide-suggestions';

const GLOBAL_PRODUCT_CONFLICT = API + '/global-product-conflict/:conflictId';
const RESOLVE_CONFLICT_BY_NEW_GLOBAL_PRODUCT =
  GLOBAL_PRODUCT_CONFLICT + '/resolve-conflict-by-split';

const GTIN_VALIDATOR = API + '/gtin_validator';
const GTIN_GS1 = GTIN_VALIDATOR + '/gs1-lookup';

const USER = API + '/user';
const USER_CREATE = USER + '/create';

const USERS = API + '/users';
const UPDATE_USER_ROLES = USERS + '/update-roles';
const USERS_OF_REJECTED_DEFAULT_L4_IMAGES = USERS + '/get-users-for-rejected-l4-images';
const USERS_GET_FOR_DROPDOWN = USERS + '/get-for-dropdown';

const ROLE = API + '/role';
const ROLES = API + '/roles';

const FILE_EXPORTS = API + '/file-exports';

const STAT = API + '/stat';
const CLASSIFICATION_STAT = STAT + '/classification';
const CLASSIFICATION_STATS = STAT + '/classification/get';
const MFR_PRODUCT_CLASSIFICATION_STAT = STAT + '/mfr-product-classification';
const VENDOR_PRODUCT_CLASSIFICATION_STAT = STAT + '/vendor-product-classification';
const MFR_PRODUCT_VERIFIED_STAT = STAT + '/mfr-product-verified';
const VENDOR_PRODUCT_VERIFIED_STAT = STAT + '/vendor-product-verified';

const TASKS = API + '/tasks';
const TASKS_COUNT_BY_STATUS = API + '/tasks/count';

const TAXONOMY = API + '/taxonomy';
const TAXONOMY_CATEGORY_NAMES = TAXONOMY + '/get-categories';
const TAXONOMY_GET = TAXONOMY + '/get-taxonomy';
const TAXONOMY_CREATE_CATEGORY = TAXONOMY + '/create-category';
const TAXONOMY_EDIT_CATEGORY_NAME = TAXONOMY + '/edit-category-name';
const TAXONOMY_EXPORT = TAXONOMY + '/export';
const TAXONOMY_DELETE_CATEGORY = TAXONOMY + '/delete-category/:category_level/:category_id';
const TAXONOMY_CATEGORY_OPTIONS = TAXONOMY + '/get-category-options';
const TAXONOMY_CATEGORY_LEVEL_OPTIONS = TAXONOMY + '/get-category-options/:category_level';
const TAXONOMY_CATEGORY_DETAILS = TAXONOMY + '/get-category-details/:category_level/:category_id';
const TAXONOMY_CHILD_CATEGORY_DETAILS = TAXONOMY + '/get-child-category-options';
const TAXONOMY_CATEGORY_OPTIONS_FOR_PRODUCT_LIST =
  TAXONOMY + '/get-category-options-product-list/:product_list_id';
const TAXONOMY_L4_DEFAULT_IMAGE = TAXONOMY + '/get-l4-default-image';
const TAXONOMY_SET_L4_DEFAULT_IMAGE = TAXONOMY + '/set-l4-default-image';
const TAXONOMY_SET_L4_DEFAULT_IMAGE_NOT_APPLICABLE =
  TAXONOMY + '/set-l4-default-image-not-applicable';
const TAXONOMY_GET_L4_CATEGORIES_BY_IMAGE_UPLOAD_STATUS = TAXONOMY + '/l3/get-l4-default-images';
const TAXONOMY_GET_L3_CATEGORIES_BY_L4_IMAGE_UPLOAD_STATUS =
  TAXONOMY + '/l3-by-l4-default-image-status';
const TAXONOMY_GET_REJECTED_L4_IMAGES_BY_USER_ID =
  TAXONOMY + '/user/get-rejected-l4-default-images';
const TAXONOMY_REVIEW_L4_DEFAULT_IMAGES = TAXONOMY + '/review-l4-default-images';
const TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS = TAXONOMY + '/get-upb-view-l1-category-options';
const TAXONOMY_UPPER_LEVELS = TAXONOMY + '/get-upper-levels';

const ACTIVITY_LOGS = API + '/activity-logs';
const ACTIVITY_LOGS_FIND = ACTIVITY_LOGS + '/find';

const ATTRIBUTE = API + '/attribute';
const ATTRIBUTE_DELETE = ATTRIBUTE + '/:attribute_id';
const ATTRIBUTE_MIGRATE_TO_GLOBAL_ATTRIBUTE = ATTRIBUTE + '/migrate-to-global-attribute';
const ATTRIBUTE_MERGE = ATTRIBUTE + '/merge-attributes';

const ATTRIBUTES = API + '/attributes';
const ALL_ATTRIBUTES = ATTRIBUTES + '/all';
const ATTRIBUTES_FOR_DATA_SOURCE_MAPPING = ATTRIBUTES + '/for-data-source-mapping';
const ATTRIBUTES_BY_CATEGORY_LEVEL = ATTRIBUTES + '/search-by-category-level';
const ATTRIBUTES_FOR_CATEGORY_LEVEL = ATTRIBUTES + '/search-by-category';
const ATTRIBUTES_FOR_CATEGORY_LEVEL_FOR_MULTI_CATEGORIES = ATTRIBUTES + '/search-by-multi-category';
const ATTRIBUTES_ASSIGNED_CATEGORIES = ATTRIBUTES + '/assigned-categories';
const ATTRIBUTES_FOR_PRODUCT = ATTRIBUTES + '/get-for-product';
const ATTRIBUTES_FOR_PRODUCT_UPDATE = ATTRIBUTES + '/update-for-product';
const ATTRIBUTION_CSV_EXPORT = ATTRIBUTES + '/export-csv';
const ATTRIBUTION_CSV_IMPORT = ATTRIBUTES + '/import-csv';
const ATTRIBUTE_CSV_IMPORT_FOR_ROSETTA_TAXONOMIES = ATTRIBUTES + '/taxonomy/import-csv';
const ATTRIBUTION_COMPLIANCE_CSV_EXPORT = ATTRIBUTES + '/file/priority-compliance';

const ATTRIBUTE_VALUE = API + '/attribute-value';
const ATTRIBUTE_VALUE_MERGE = ATTRIBUTE_VALUE + '/merge-attribute-value';
const ATTRIBUTE_VALUE_DETAILS = ATTRIBUTE_VALUE + '/:attribute_value_id';
const ATTRIBUTE_VALUES = API + '/attribute-values';
const ATTRIBUTE_VALUES_BY_ATTRIBUTE_NAME = ATTRIBUTE_VALUES + '/by-attribute-name';
const GET_DISPLAY_VALUE_ENABLED_RECORDS = ATTRIBUTE_VALUES + '/display-values';
const ADD_DISPLAY_VALUE = ATTRIBUTE_VALUES + '/add-display-values';

const ATTRIBUTE_VALUE_MAP = API + '/attribute-value-map';
const ATTRIBUTE_VALUE_MAPS = API + '/attribute-value-maps';

const ATTRIBUTE_VALUE_GROUP_MAP = API + '/attribute-value-group-map';
const ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID = ATTRIBUTE_VALUE_GROUP_MAP + '/attribute';

const ATTRIBUTE_VALUE_GROUP_MAPS = API + '/attribute-value-group-maps';

const GLOBAL_ATTRIBUTE = API + '/global-attribute';
const GLOBAL_ATTRIBUTE_MIGRATE_TO_ATTRIBUTE = GLOBAL_ATTRIBUTE + '/migrate-to-attribute';
const GLOBAL_ATTRIBUTE_DETAILS = GLOBAL_ATTRIBUTE + '/:global_attribute_id';
const GLOBAL_ATTRIBUTE_DELETE = GLOBAL_ATTRIBUTE + '/:global_attribute_id';

const GLOBAL_ATTRIBUTES = API + '/global-attributes';
const GLOBAL_ATTRIBUTES_FOR_SECTION = GLOBAL_ATTRIBUTES + '/attribute-section';

const GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS = API + '/global-vendor-attribute-requirements';
const GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS_FOR_ATTRIBUTE =
  GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS + '/:attribute_id';

const ATTRIBUTES_MAP = API + '/attributes_map';
const ATTRIBUTES_MAP_DETAILS = ATTRIBUTES_MAP + '/:attribute_map_id';

const ATTRIBUTES_MAPS = API + '/attributes_maps';
const ATTRIBUTES_MAPS_CREATE = ATTRIBUTES_MAPS + '/add';
const ATTRIBUTES_MAPS_MULTI_CREATE = ATTRIBUTES_MAPS + '/add-multi';
const ATTRIBUTES_MAPS_DETAILS = ATTRIBUTES_MAPS + '/:attribute_map_ids';

const ATTRIBUTES_SECTION = API + '/attribute-section';
const ATTRIBUTES_SUB_SECTIONS_FOR_SECTION =
  ATTRIBUTES_SECTION + '/:attribute_section_id/sub_sections';
const ATTRIBUTES_SECTIONS = API + '/attribute-sections';

const ATTRIBUTES_SUB_SECTION = API + '/attribute-sub-section';
const ATTRIBUTES_SUB_SECTION_ALL = ATTRIBUTES_SUB_SECTION + '/all';
const ATTRIBUTES_SUB_SECTIONS_WITH_SECTIONS = ATTRIBUTES_SUB_SECTION + '/all/with-sections';
const ATTRIBUTES_SUB_SECTIONS_FOR_A_SECTION =
  ATTRIBUTES_SUB_SECTION + '/:attribute_sub_section_id/id';

const BRAND = API + '/brand';
const BRAND_HARVEST = BRAND + '/harvest';
const SIMILAR_BRAND_INFO = BRAND + '/get-similar-brand-name-info';
const SIMILAR_BRAND_UPDATE = BRAND + '/update-similar-brand-name-info';
const GET_SIMILAR_BRAND_NAME_INFO = BRAND + '/get-similar-brand-name-info-v2';
const UPDATE_SIMILAR_BRAND = BRAND + '/update-similar-brand-name-info';
const UPDATE_SIMILAR_BRAND_DISPLAY_STATUS = BRAND + '/update-display-status';

const BRANDS = API + '/brands';
const BRANDS_HARVEST = BRANDS + '/harvest';
const BRANDS_FOR_HARVEST = BRANDS + '/harvest';
const BRANDS_FOR_QA = BRANDS + '/for-QA';
const BRANDS_FOR_HARVEST_GROUPED = BRANDS + '/harvest-group-by-domain';
const BRANDS_BY_IDS = BRANDS + '/get-by-ids';
const BRANDS_GET_BY_ID = BRANDS + '/:brand_id';
const BRANDS_GET_BY_NAME = BRANDS + '/by-name/:brand_name';
const BRANDS_GET_BY_MANUFACTURER = BRANDS + '/manufacturer-brands';
const BRANDS_GENERATE_ACTIVITY_REPORT = BRANDS + '/brands-generate-activity-report';
const BRANDS_CREATE = BRANDS + '/create';
const BRANDS_UPDATE = BRANDS + '/update';
const BRANDS_UPDATE_MULTI = BRANDS + '/update-brands';
const BRANDS_UPDATE_FROM_FSA = BRANDS + '/update-from-fsa';
const BRANDS_UPDATE_FROM_QA = BRANDS + '/update-from-qa';
const BRANDS_MERGE = BRANDS + '/merge';
const BRANDS_DELETE = BRANDS + '/delete';
const BRANDS_FOR_DROPDOWN = BRANDS + '/get-for-dropdown';
const BRANDS_IMPORT = BRANDS + '/import-brands';
const BRANDS_DELETE_UNLINKED = BRANDS + '/delete-unlinked-brands';
const BRANDS_UNVERIFY = BRANDS + '/unverify-brands';
const BRANDS_DOWNLOAD_CSV = BRANDS + '/download-csv';
const BRANDS_ACTIVITY_LOG = BRANDS + '/activity-log';
const BRANDS_DELETE_SIMILAR_BRAND_NAMES = BRANDS + '/similar-brand-names/:similar_brand_name_ids';
const BRANDS_PRODUCTS = BRANDS + '/products';
const FOR_LEADERBOARD = BRANDS + '/for-leaderboard';

const CHANGE_LOGS = API + '/change-logs';
const CHANGE_LOGS_LATEST_RECORD = CHANGE_LOGS + '/latest-record';
const CHANGE_LOGS_BY_STATUS = CHANGE_LOGS + '/by-status';
const CHANGE_LOGS_BY_REQUEST_ID = CHANGE_LOGS + '/by-request-id';
const CHANGE_LOGS_BY_IDS = CHANGE_LOGS + '/get-by-ids';
const CHANGE_LOGS_FOR_GLOBAL_PRODUCT = CHANGE_LOGS + '/for-global-product';
const CHANGE_LOGS_UPDATE_STATUS = CHANGE_LOGS + '/update-status';
const CHANGE_LOG_GROUP = CHANGE_LOGS + '/change-log-group';
const CHANGE_LOGS_DELETED_IMAGE_DETAILS = CHANGE_LOGS + '/deleted-image-details';

const CATALOG_FILTER_ATTRIBUTES = API + '/catalog-filter-attributes';
const CATALOG_FILTER_ATTRIBUTES_VENDOR = CATALOG_FILTER_ATTRIBUTES + '/get-by-global-vendor-id';
const CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE = CATALOG_FILTER_ATTRIBUTES + '/create-new-filter';
const CATALOG_FILTER_ATTRIBUTES_VENDOR_UPDATE = CATALOG_FILTER_ATTRIBUTES + '/update-filter';
const CATALOG_FILTER_ATTRIBUTES_VENDOR_DELETE = CATALOG_FILTER_ATTRIBUTES + '/delete-filter';
const CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE_PRESET =
  CATALOG_FILTER_ATTRIBUTES + '/create-preset-from-filter';
const CATALOG_FILTER_PRESETS_FOR_DROPDOWN = CATALOG_FILTER_ATTRIBUTES + '/get-presets-for-dropdown';
const CATALOG_FILTER_PRESET = CATALOG_FILTER_ATTRIBUTES + '/get-preset';

const PRODUCT_LIST_SINGLE = API + '/product-list';
const PRODUCT_LIST_ID = PRODUCT_LIST_SINGLE + '/:product_list_id';

const PRODUCT_LISTS = API + '/product-lists';
const ALL_PRODUCT_LISTS = PRODUCT_LISTS + '/all';
const PRODUCT_LIST_IMAGE_FOR_TAGGING = PRODUCT_LISTS + '/images-for-tagging/:product_list_id';
const PRODUCT_LIST = PRODUCT_LISTS + '/:product_list_id';
const PRODUCT_LIST_PRODUCTS = PRODUCT_LIST + '/products';
const PRODUCT_LIST_PRODUCTS_COVERAGE_METRICS = PRODUCT_LISTS + '/re-calculate-coverage-metrics';
const PRODUCT_LIST_BROKEN_IMAGE_DELETE =
  PRODUCT_LISTS + '/remove-broken-image-urls/:product_list_id';
const PRODUCT_LIST_ASSIGN_THUMBNAILS = PRODUCT_LISTS + '/assign-thumbnails';
const PRODUCT_LIST_UPDATE_PRODUCTS = PRODUCT_LISTS + '/update-products';
const PRODUCT_LIST_VENDOR_CATEGORIES =
  PRODUCT_LISTS + '/get-vendor-categories-for-dropdown/:product_list_id';
const PRODUCT_LIST_UPB_MATCH_CSV_EXPORT = PRODUCT_LISTS + '/file/upb_matches';
const PRODUCT_LIST_ATTRIBUTION_CSV_EXPORT = PRODUCT_LISTS + '/file/attribute_export';
const PRODUCT_LIST_ATTRIBUTION_EXPORT = PRODUCT_LISTS + '/file/csv_attribute_export';
const PRODUCT_LIST_IMAGE_SOURCE_EXPORT = PRODUCT_LISTS + '/file/csv_image_source_export';
const PRODUCT_LIST_STATUS_UPDATE_ON_PRODUCTS = PRODUCT_LISTS + '/product-status-update';
const PRODUCT_LIST_IMAGE_CLEANUP = PRODUCT_LISTS + '/image-cleanup';
const PRODUCT_LIST_REFRESH_CACHE = PRODUCT_LISTS + '/cache-reset/:product_list_id';
const PRODUCT_LIST_CD_PRODUCT_SCORE = PRODUCT_LISTS + '/cd-product-score/:product_list_id';

const GLOBAL_VENDOR = API + '/global-vendor';
const GLOBAL_VENDOR_UPDATE_IMAGE_FLAG = GLOBAL_VENDOR + '/update-flags';

const GLOBAL_VENDORS = API + '/global-vendors';
const GLOBAL_VENDORS_ASSIGN_THUMBNAILS = GLOBAL_VENDORS + '/assign-thumbnails/:vendor_id';
const GLOBAL_VENDORS_BROKEN_IMAGE_DELETE = GLOBAL_VENDORS + '/remove-broken-image-urls';
const GLOBAL_VENDORS_CREATE_DEMO_CATALOG = GLOBAL_VENDORS + '/create-demo-catalog';
const GLOBAL_VENDORS_GET_BY_ID = GLOBAL_VENDORS + '/:vendor_id';
const GLOBAL_VENDORS_BY_IDS = GLOBAL_VENDORS + '/get-by-ids';
const GLOBAL_VENDORS_FOR_DROPDOWN = GLOBAL_VENDORS + '/get-for-dropdown';
const GLOBAL_VENDORS_UPDATE = GLOBAL_VENDORS + '/update';
const GLOBAL_VENDORS_CREATE = GLOBAL_VENDORS + '/create';

const GLOBAL_MANUFACTURERS = API + '/global-manufacturers';
const GLOBAL_MANUFACTURERS_CREATE = GLOBAL_MANUFACTURERS + '/create';
const GLOBAL_MANUFACTURERS_GET_BY_ID = GLOBAL_MANUFACTURERS + '/:manufacturer_id';
const GLOBAL_MANUFACTURERS_GET_BY_NAME = GLOBAL_MANUFACTURERS + '/by-name/:manufacturer_name';
const GLOBAL_MANUFACTURERS_BY_IDS = GLOBAL_MANUFACTURERS + '/get-by-ids';
const GLOBAL_MANUFACTURERS_FOR_DROPDOWN = GLOBAL_MANUFACTURERS + '/get-for-dropdown';
const GLOBAL_MANUFACTURERS_UPDATE = GLOBAL_MANUFACTURERS + '/update';
const GLOBAL_MANUFACTURERS_MERGE = GLOBAL_MANUFACTURERS + '/merge';
const GLOBAL_MANUFACTURERS_ASSIGN_THUMBNAILS =
  GLOBAL_MANUFACTURERS + '/assign-thumbnails/:manufacturer_id';
const GLOBAL_MANUFACTURERS_BROKEN_IMAGE_DELETE =
  GLOBAL_MANUFACTURERS + '/remove-broken-image-urls/:manufacturer_id';
const GLOBAL_MANUFACTURERS_DELETE_UNLINKED =
  GLOBAL_MANUFACTURERS + '/delete-unlinked-manufacturers';
const GLOBAL_MANUFACTURERS_UNVERIFY = GLOBAL_MANUFACTURERS + '/unverify-manufacturers';
const GLOBAL_MANUFACTURERS_DOWNLOAD_CSV = GLOBAL_MANUFACTURERS + '/download-csv';
const GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA = GLOBAL_MANUFACTURERS + '/update-from-fsa';
const GLOBAL_MANUFACTURERS_CONVERT_TO_DISTRIBUTOR =
  GLOBAL_MANUFACTURERS + '/convert-to-distributor/:manufacturer_id';
const GLOBAL_MANUFACTURERS_ACTIVITY_LOG = GLOBAL_MANUFACTURERS + '/activity-log';
const GLOBAL_MANUFACTURERS_DELETE_SIMILAR_MANUFACTURERS_NAMES =
  GLOBAL_MANUFACTURERS + '/similar-manufacturer-names/:similar_manufacturer_name_ids';
const GLOBAL_MANUFACTURERS_PRODUCTS = GLOBAL_MANUFACTURERS + '/products';

const GLOBAL_MANUFACTURER = API + '/global-manufacturer';
const GLOBAL_MANUFACTURER_PRODUCT_CREATION = GLOBAL_MANUFACTURER + '/create-manufacturer-products';

const DASHBOARD = API + '/dashboard';
const DASHBOARD_BRAND_HARVESTING = DASHBOARD + '/brand-harvesting';

const DATA_SOURCE = API + '/data-source';
const DATA_SOURCE_ALL = DATA_SOURCE + '/all';
const DATA_SOURCE_ARCHIVE = DATA_SOURCE + '/archive';
const DATA_SOURCE_ASSIGN_SKUS = DATA_SOURCE + '/assign-upb-skus';
const DATA_SOURCE_CONNECTED_PRODUCTS = DATA_SOURCE + '/connected-products';
const DATA_SOURCE_CREATE = DATA_SOURCE + '/create';
const DATA_SOURCE_GET_BY_ID = DATA_SOURCE + '/:data_source_id';
const DATA_SOURCE_URL = DATA_SOURCE + '/:data_source_id/presigned-url';
const DATA_SOURCE_LAST_RUN_DATA = DATA_SOURCE + '/last-run-data';
const DATA_SOURCE_RUN_DATA = DATA_SOURCE + '/run-data';
const DATA_SOURCE_UPDATE = DATA_SOURCE + '/update';
const DATA_SOURCE_AUTO_MAP = DATA_SOURCE + '/auto-map-attribute-values';
const DATA_SOURCE_PREPROCESS_EXPORT = DATA_SOURCE + '/file/preprocessed_export';
const DATA_SOURCE_RESET_ATTRIBUTE_MAP = DATA_SOURCE + '/reset-attribute-map';
const DATA_SOURCE_COLUMN_VALUES = DATA_SOURCE + '/get-column-attribute-values';

const DATA_SOURCE_ORIGIN = API + '/data-source-origin';
const DATA_SOURCE_ORIGIN_ALL = DATA_SOURCE_ORIGIN + '/all';
const DATA_SOURCE_ORIGIN_CREATE = DATA_SOURCE_ORIGIN + '/create';

const DATA_SOURCE_RUN = API + '/data-source-run';
const DATA_SOURCE_RUN_URL = DATA_SOURCE_RUN + '/:data_source_run_id/presigned-url';

const DATA_STREAM = API + '/data-stream';
const DATA_STREAM_GET_BY_ID = DATA_STREAM + '/:data_stream_id';
const DATA_STREAM_PROCESS = DATA_STREAM + '/process';

const DATA_STREAMS = API + '/data-streams';

const DATA_STREAM_SYNC_JOB = API + '/data-stream-sync-job';

const PRODUCT_IMAGES = API + '/product-images';
const IMAGE_RECOMMENDATIONS = PRODUCT_IMAGES + '/recommendations-for-l4';
const WEB_IMAGE_RECOMMENDATIONS = PRODUCT_IMAGES + '/web-recommendations-for-product';
const ADD_IMAGES_TO_PRODUCT = PRODUCT_IMAGES + '/add-images';
const GET_IMAGES = PRODUCT_IMAGES + '/get-assets';
const OPTIMIZE_IMAGES = PRODUCT_IMAGES + '/product-list/optimize-images';
const PRODUCT_IMAGES_DELETE_MULTI = PRODUCT_IMAGES + '/delete-multi';
const OTHER_IMAGES = PRODUCT_IMAGES + '/other-images';

const NUTRITIONAL_PANELS = API + '/nutritional-panels';
const NUTRITIONAL_PANELS_UPLOAD = NUTRITIONAL_PANELS + '/upload-nutritional-panels';
const NUTRITIONAL_PANELS_FOR_VIEW = NUTRITIONAL_PANELS + '/get-nutritional-panels';
const NUTRITIONAL_PANELS_SEND_PREPROCESSED_FILE =
  NUTRITIONAL_PANELS + '/send-pre-processed-file-for-data-import';
const NUTRITIONAL_PANELS_UPLOAD_FOR_PRODUCT =
  NUTRITIONAL_PANELS + '/upload-nutritional-panels-for-product';
const NUTRITIONAL_PANELS_OF_PRODUCT = NUTRITIONAL_PANELS + '/get-panels-for-product';
const NUTRITIONAL_PANELS_SCRAPED_DETAILS_OF_PRODUCT =
  NUTRITIONAL_PANELS + '/get-scraped-nutritional-panel-values';
const NUTRITIONAL_PANELS_APPROVE_SCRAPED_DATA =
  NUTRITIONAL_PANELS + '/approve-scraped-nutritional-panel-data';

const PRODUCT_IMAGE = API + '/product-image';
const PRODUCT_IMAGE_MAKE_THUMBNAIL = PRODUCT_IMAGE + '/make-thumbnail';
const PRODUCT_IMAGE_TAGGING_PREDICTIONS = PRODUCT_IMAGE + '/ml/send-image-data';

const SKU_MAPPINGS = API + '/sku-mappings';
const SKU_MAPPINGS_VERIFY = SKU_MAPPINGS + '/verify-global-products';
const SKU_MAPPINGS_CANCEL = SKU_MAPPINGS + '/update-status-to-cancel';
const SKU_MAPPINGS_VERIFY_PRODUCTS = SKU_MAPPINGS + '/verify-products-in-global-product';
const SKU_MAPPINGS_UNLINK_PRODUCTS = SKU_MAPPINGS + '/unlink-products';
const SKU_MAPPINGS_UNLINK_ALL_PRODUCTS = SKU_MAPPINGS + '/unlink-all-products';
const SKU_MAPPINGS_VERIFY_LINKS = SKU_MAPPINGS + '/verify-links';
const SKU_MAPPINGS_UNVERIFY_LINKS = SKU_MAPPINGS + '/unverify-links';
const SKU_MAPPINGS_FSA_UPDATE = SKU_MAPPINGS + '/update-fsa-mappings';
const SKU_MAPPINGS_DELETE_PRODUCTS = SKU_MAPPINGS + '/delete-products';
const SKU_MAPPINGS_UNLINK_PRODUCT_GROUP = SKU_MAPPINGS + '/unlink-product-group';
const SKU_MAPPINGS_CONVERT_PRODUCTS = SKU_MAPPINGS + '/convert-to-distributor-products';
const SKU_MAPPINGS_MERGE_PRODUCTS = SKU_MAPPINGS + '/merge-manufacturer-products';
const SKU_MAPPINGS_DELETE_UNVERIFIED_MANUFACTURER_PRODUCTS =
  SKU_MAPPINGS + '/delete-unverified-manufacturer-products';
const SKU_MAPPINGS_CONVERT_MANUFACTURER_PRODUCTS_OF_PRODUCT_LIST =
  SKU_MAPPINGS + '/convert-manufacturer-products-of-product-list';
const SKU_MAPPINGS_CREATE_MANUFACTURER_PRODUCTS =
  SKU_MAPPINGS + '/create-mfr-product-from-vendor-product';

const AUTO_CLASSIFICATION_PRODUCT = API + '/auto-classification-product';
const AUTO_CLASSIFICATION_PRODUCT_UPDATE_CATEGORIES =
  AUTO_CLASSIFICATION_PRODUCT + '/update-categories';
const AUTO_CLASSIFICATION_PRODUCT_REJECT_PREDICTIONS =
  AUTO_CLASSIFICATION_PRODUCT + '/reject-category-predictions';
const AUTO_CLASSIFICATION_PRODUCT_DOWNLOAD_CSV = AUTO_CLASSIFICATION_PRODUCT + '/download-csv';
const AUTO_CLASSIFICATION_PRODUCT_SEND_DATA =
  AUTO_CLASSIFICATION_PRODUCT + '/send-product-data-to-ml-server';

const PRODUCT_OUTLIERS = API + '/product-outliers';
const PRODUCT_OUTLIERS_HIDE_SUGGESTIONS = PRODUCT_OUTLIERS + '/hide-suggestions';
const PRODUCT_OUTLIERS_CHANGES = PRODUCT_OUTLIERS + '/changes';
const PRODUCT_OUTLIERS_CHANGED_PRODUCTS_EXPORT = PRODUCT_OUTLIERS + '/changes-export';
const PRODUCT_OUTLIERS_QA_CHANGES = PRODUCT_OUTLIERS + '/qa-global-product-changes';
const PRODUCT_OUTLIERS_SIMILARITY_SCORES =
  PRODUCT_OUTLIERS + '/send-data-for-product-similarity-predication';
const PRODUCT_OUTLIERS_APPROVE_CHANGES = PRODUCT_OUTLIERS + '/approve-global-product-changes';
const PRODUCT_OUTLIERS_REJECT_CHANGES = PRODUCT_OUTLIERS + '/reject-global-product-changes';

const SCHEMA = API + '/schema';
const DELETE_SCHEMA = SCHEMA + '/:schema_id';
const SCHEMAS = API + '/schemas';

const SCHEMA_ATTRIBUTE = API + '/schema-attribute';
const ATTRIBUTE_SCHEMA_IMPORT = SCHEMA_ATTRIBUTE + '/import';

const SCHEMA_ATTRIBUTES = API + '/schema-attributes';
const SCHEMA_ATTRIBUTES_DELETE = SCHEMA_ATTRIBUTES + '/:record_ids';
const SCHEMA_ATTRIBUTES_EXPORT = SCHEMA_ATTRIBUTES + '/download-csv';

const SIMILAR_BRANDS = API + '/similar-brands';

const SIMILAR_MANUFACTURERS = API + '/similar-manufacturers';

const DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS = API + '/duplicate-global-product-suggestions';
const DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_MERGE =
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS + '/merge-global-products';
const DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA =
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS + '/dropdown-data';
const DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS =
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS + '/link-products';
const DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT =
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS + '/reject';

const FILES = API + '/files';
const PUBLIC_UPLOAD_FILE = FILES + '/public-upload';
const UPLOAD_FILE = FILES + '/upload';
const PRESIGNED_URL = FILES + '/presigned-url';

const TOKEN_CONFIG = API + '/token-config';

const SQS = API + '/sqs';
const SQS_ATTRIBUTE_MESSAGE_COUNT = SQS + '/attributes/message-count';

const TOOLS = API + '/tools';
const GS1_XML_DECODE = TOOLS + '/gs1-xml-decode';

const ANNUALIZED_GMV = API + '/annualized-gmv';
const TOTAL_ANNUALIZED_GMV = ANNUALIZED_GMV + '/total';

function getURL(url, params) {
  let res = url;
  Object.keys(params).forEach(key => {
    res = res.replace(`:${key}`, params[key]);
  });
  return res;
}

function getURLWithSearchParams(url, searchParams) {
  const paramString = new URLSearchParams(searchParams).toString();
  return `${url}${paramString ? `?${paramString}` : ''}`;
}

export default SERVER;
export {
  ACTIVITY_LOGS_FIND,
  ADD_IMAGES_TO_PRODUCT,
  ADD_MANUFACTURER_THUMBNAIL_ATTRIBUTE,
  ADD_TAXONOMY_FRIENDLY_NAMES,
  ADD_VENDOR_THUMBNAIL_ATTRIBUTE,
  API_INTEGRATION,
  API_INTEGRATION_DELETE,
  ALL_ATTRIBUTES,
  ALL_PRODUCT_LISTS,
  ANNUALIZED_GMV,
  TOTAL_ANNUALIZED_GMV,
  ATTRIBUTE_DELETE,
  ATTRIBUTE_MIGRATE_TO_GLOBAL_ATTRIBUTE,
  ATTRIBUTE_VALUE_DETAILS,
  ATTRIBUTE_VALUE_MERGE,
  ATTRIBUTE_VALUE_GROUP_MAP,
  ATTRIBUTE_VALUE_GROUP_MAPS_BY_ATTRIBUTE_ID,
  ATTRIBUTE_VALUE_GROUP_MAPS,
  ATTRIBUTE_VALUE,
  ATTRIBUTE_VALUES_BY_ATTRIBUTE_NAME,
  GET_DISPLAY_VALUE_ENABLED_RECORDS,
  ADD_DISPLAY_VALUE,
  ATTRIBUTE_VALUE_MAP,
  ATTRIBUTE_VALUE_MAPS,
  ATTRIBUTE_VALUES,
  ATTRIBUTE,
  ATTRIBUTE_MERGE,
  ATTRIBUTES_BY_CATEGORY_LEVEL,
  ATTRIBUTES_FOR_CATEGORY_LEVEL_FOR_MULTI_CATEGORIES,
  ATTRIBUTES_FOR_CATEGORY_LEVEL,
  ATTRIBUTES_ASSIGNED_CATEGORIES,
  ATTRIBUTES_FOR_DATA_SOURCE_MAPPING,
  ATTRIBUTES_FOR_PRODUCT_UPDATE,
  ATTRIBUTES_FOR_PRODUCT,
  ATTRIBUTES_MAP_DETAILS,
  ATTRIBUTES_MAP,
  ATTRIBUTES_MAPS_DETAILS,
  ATTRIBUTES_MAPS,
  ATTRIBUTES_MAPS_CREATE,
  ATTRIBUTES_MAPS_MULTI_CREATE,
  ATTRIBUTES_SECTION,
  ATTRIBUTES_SECTIONS,
  ATTRIBUTES_SUB_SECTION_ALL,
  ATTRIBUTES_SUB_SECTIONS_WITH_SECTIONS,
  ATTRIBUTES_SUB_SECTIONS_FOR_A_SECTION,
  ATTRIBUTES_SUB_SECTIONS_FOR_SECTION,
  ATTRIBUTES,
  ATTRIBUTION_COMPLIANCE_CSV_EXPORT,
  ATTRIBUTION_CSV_EXPORT,
  ATTRIBUTION_CSV_IMPORT,
  ATTRIBUTE_CSV_IMPORT_FOR_ROSETTA_TAXONOMIES,
  AUTO_CLASSIFICATION_PRODUCT,
  AUTO_CLASSIFICATION_PRODUCT_DOWNLOAD_CSV,
  AUTO_CLASSIFICATION_PRODUCT_REJECT_PREDICTIONS,
  AUTO_CLASSIFICATION_PRODUCT_SEND_DATA,
  AUTO_CLASSIFICATION_PRODUCT_UPDATE_CATEGORIES,
  BRANDS_ACTIVITY_LOG,
  BRANDS_FOR_HARVEST,
  BRANDS_FOR_QA,
  BRANDS_UPDATE_FROM_QA,
  BRANDS_FOR_HARVEST_GROUPED,
  BRANDS_HARVEST,
  BRANDS_BY_IDS,
  BRANDS_CREATE,
  BRANDS_DELETE,
  BRANDS_DELETE_SIMILAR_BRAND_NAMES,
  BRANDS_DELETE_UNLINKED,
  BRANDS_DOWNLOAD_CSV,
  BRANDS_FOR_DROPDOWN,
  BRANDS_GET_BY_ID,
  BRANDS_GET_BY_NAME,
  BRANDS_GET_BY_MANUFACTURER,
  BRANDS_GENERATE_ACTIVITY_REPORT,
  BRANDS_IMPORT,
  BRANDS_MERGE,
  BRANDS_PRODUCTS,
  FOR_LEADERBOARD,
  BRANDS_UNVERIFY,
  BRANDS_UPDATE,
  BRANDS_UPDATE_MULTI,
  BRANDS_UPDATE_FROM_FSA,
  BRANDS,
  BRAND,
  BRAND_HARVEST,
  SIMILAR_BRAND_INFO,
  SIMILAR_BRAND_UPDATE,
  GET_SIMILAR_BRAND_NAME_INFO,
  UPDATE_SIMILAR_BRAND,
  UPDATE_SIMILAR_BRAND_DISPLAY_STATUS,
  CATEGORIES_WITH_PENDING_COUNTS,
  CATEGORIES,
  CATEGORY_DETAILS,
  CATEGORY_EDIT_NAME,
  CATEGORY_OPTIONS,
  CATEGORY,
  CATALOG_FILTER_ATTRIBUTES_VENDOR,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_UPDATE,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_DELETE,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE_PRESET,
  CATALOG_FILTER_PRESETS_FOR_DROPDOWN,
  CATALOG_FILTER_PRESET,
  CHANGE_LOG_GROUP,
  CHANGE_LOGS_BY_IDS,
  CHANGE_LOGS_BY_REQUEST_ID,
  CHANGE_LOGS_BY_STATUS,
  CHANGE_LOGS_DELETED_IMAGE_DETAILS,
  CHANGE_LOGS_FOR_GLOBAL_PRODUCT,
  CHANGE_LOGS_LATEST_RECORD,
  CHANGE_LOGS_UPDATE_STATUS,
  CHANGE_LOGS,
  CLEAN_INVALID_PRODUCTS,
  CURRENT_USER,
  DASHBOARD,
  DASHBOARD_BRAND_HARVESTING,
  DATA_SOURCE_ALL,
  DATA_SOURCE_ASSIGN_SKUS,
  DATA_SOURCE_CONNECTED_PRODUCTS,
  DATA_SOURCE_CREATE,
  DATA_SOURCE_GET_BY_ID,
  DATA_SOURCE_LAST_RUN_DATA,
  DATA_SOURCE_ORIGIN_ALL,
  DATA_SOURCE_ORIGIN_CREATE,
  DATA_SOURCE_PROCESS,
  DATA_SOURCE_RUN_DATA,
  DATA_SOURCE_PREPROCESS_EXPORT,
  DATA_SOURCE_RUN_URL,
  DATA_SOURCE_RESET_ATTRIBUTE_MAP,
  DATA_SOURCE_COLUMN_VALUES,
  DATA_STREAM,
  DATA_STREAM_GET_BY_ID,
  DATA_STREAM_PROCESS,
  DATA_STREAMS,
  DATA_STREAM_SYNC_JOB,
  DELETE_TASK,
  KILL_TASK,
  EXECUTE_TASK,
  DATA_SOURCE_ARCHIVE,
  DATA_SOURCE_UPDATE,
  DATA_SOURCE_AUTO_MAP,
  DATA_SOURCE_URL,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_MERGE,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_DROPDOWN_DATA,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_LINK_PRODUCTS,
  DUPLICATE_GLOBAL_PRODUCT_SUGGESTIONS_REJECT,
  EXPORT_ATTRIBUTES,
  IMPORT_ATTRIBUTES,
  UPDATE_ATTRIBUTES_BY_ATTRIBUTE_ID,
  IMPORT_ATTRIBUTE_VALUES,
  DELETE_ORPHANED_GLOBAL_PRODUCTS,
  EXPORT_DATA_CUT_DRY,
  EXPORT_HILLCREST_ERRORS,
  EXPORT_INCORRECTLY_LINKED_PRODUCTS_LIST,
  EXPORT_P0_CD,
  EXPORT_PRODUCTS_CD,
  FILE_EXPORTS,
  FIX_INVALID_GLOBAL_PRODUCT_NAMES,
  FIX_MANUFACTURER_DATA,
  FIX_VENDOR_DATA,
  GET_IMAGES,
  OPTIMIZE_IMAGES,
  getURL,
  getURLWithSearchParams,
  GLOBAL_ATTRIBUTE_DETAILS,
  GLOBAL_ATTRIBUTE_DELETE,
  GLOBAL_ATTRIBUTE,
  GLOBAL_ATTRIBUTES_FOR_SECTION,
  GLOBAL_ATTRIBUTES,
  GLOBAL_ATTRIBUTE_MIGRATE_TO_ATTRIBUTE,
  GLOBAL_MANUFACTURERS_ACTIVITY_LOG,
  GLOBAL_MANUFACTURERS_ASSIGN_THUMBNAILS,
  GLOBAL_MANUFACTURERS_BROKEN_IMAGE_DELETE,
  GLOBAL_MANUFACTURERS_CONVERT_TO_DISTRIBUTOR,
  GLOBAL_MANUFACTURERS_DELETE_SIMILAR_MANUFACTURERS_NAMES,
  GLOBAL_MANUFACTURERS_DELETE_UNLINKED,
  GLOBAL_MANUFACTURERS_DOWNLOAD_CSV,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_MANUFACTURERS_GET_BY_ID,
  GLOBAL_MANUFACTURERS_GET_BY_NAME,
  GLOBAL_MANUFACTURERS_BY_IDS,
  GLOBAL_MANUFACTURERS_MERGE,
  GLOBAL_MANUFACTURERS_PRODUCTS,
  GLOBAL_MANUFACTURERS_UNVERIFY,
  GLOBAL_MANUFACTURERS_UPDATE,
  GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA,
  GLOBAL_MANUFACTURERS,
  GLOBAL_MANUFACTURERS_CREATE,
  GLOBAL_MANUFACTURER,
  GLOBAL_MANUFACTURER_PRODUCT_CREATION,
  GLOBAL_PRODUCT_MERGE_DUPLICATES,
  GLOBAL_PRODUCT_REMOVE_CATEGORIES,
  GLOBAL_PRODUCT,
  GLOBAL_PRODUCT_PDP,
  GLOBAL_PRODUCTS_BY_IDS,
  GLOBAL_PRODUCTS_BY_SEARCH,
  GLOBAL_PRODUCTS_DOWNLOAD_CSV,
  GLOBAL_PRODUCTS_MARK_SPECIALLY_UPLOADED,
  GLOBAL_PRODUCTS_UPDATE_CATEGORIES,
  GLOBAL_PRODUCTS,
  GLOBAL_PRODUCT_VENDOR_CLONE,
  GLOBAL_PRODUCT_VENDOR_MARK_AS_DEMO,
  GLOBAL_PRODUCT_VENDOR_LINK_SUGGESTIONS,
  GLOBAL_PRODUCT_VENDOR_MERGE_ML_SUGGESTED,
  GLOBAL_PRODUCT_VENDOR_HIDE_SUGGESTIONS,
  GLOBAL_VENDOR_UPDATE_IMAGE_FLAG,
  GLOBAL_VENDORS_ASSIGN_THUMBNAILS,
  GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS_FOR_ATTRIBUTE,
  GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS,
  GLOBAL_VENDORS_BROKEN_IMAGE_DELETE,
  GLOBAL_VENDORS_BY_IDS,
  GLOBAL_VENDORS,
  GLOBAL_VENDORS_CREATE,
  GLOBAL_VENDORS_CREATE_DEMO_CATALOG,
  GLOBAL_VENDORS_FOR_DROPDOWN,
  GLOBAL_VENDORS_UPDATE,
  GLOBAL_VENDORS_GET_BY_ID,
  GS1_XML_DECODE,
  GTIN_GS1,
  HILLCREST_PRODUCT_RESTORE,
  FSA_SKU_MAPPINGS_EXPORT,
  IMAGE_RECOMMENDATIONS,
  GTIN_VALIDATOR_CSV,
  isLocalhost,
  isStagingServer,
  isSandboxServer,
  L1_CATEGORY_OPTIONS,
  L2_CATEGORY_OPTIONS,
  L3_CATEGORY_OPTIONS,
  L4_CATEGORY_OPTIONS,
  LOGIN,
  LOGOUT,
  NUTRITIONAL_PANELS,
  NUTRITIONAL_PANELS_APPROVE_SCRAPED_DATA,
  NUTRITIONAL_PANELS_FOR_VIEW,
  NUTRITIONAL_PANELS_OF_PRODUCT,
  NUTRITIONAL_PANELS_SCRAPED_DETAILS_OF_PRODUCT,
  NUTRITIONAL_PANELS_SEND_PREPROCESSED_FILE,
  NUTRITIONAL_PANELS_UPLOAD,
  NUTRITIONAL_PANELS_UPLOAD_FOR_PRODUCT,
  OTHER_IMAGES,
  PRODUCT_IMAGE,
  PRODUCT_IMAGES,
  PRODUCT_IMAGES_DELETE_MULTI,
  PRODUCT_IMAGE_MAKE_THUMBNAIL,
  PRODUCT_IMAGE_TAGGING_PREDICTIONS,
  PRODUCT_LIST_ID,
  PRODUCT_LIST_PRODUCTS,
  PRODUCT_LIST,
  PRODUCT_LISTS,
  PRODUCT_LIST_ASSIGN_THUMBNAILS,
  PRODUCT_LIST_ATTRIBUTION_CSV_EXPORT,
  PRODUCT_LIST_ATTRIBUTION_EXPORT,
  PRODUCT_LIST_BROKEN_IMAGE_DELETE,
  PRODUCT_LIST_CD_PRODUCT_SCORE,
  PRODUCT_LIST_IMAGE_CLEANUP,
  PRODUCT_LIST_IMAGE_FOR_TAGGING,
  PRODUCT_LIST_PRODUCTS_COVERAGE_METRICS,
  PRODUCT_LIST_IMAGE_SOURCE_EXPORT,
  PRODUCT_LIST_STATUS_UPDATE_ON_PRODUCTS,
  PRODUCT_LIST_REFRESH_CACHE,
  PRODUCT_LIST_UPB_MATCH_CSV_EXPORT,
  PRODUCT_LIST_UPDATE_PRODUCTS,
  PRODUCT_LIST_VENDOR_CATEGORIES,
  PRODUCT_OUTLIERS,
  PRODUCT_OUTLIERS_APPROVE_CHANGES,
  PRODUCT_OUTLIERS_CHANGES,
  PRODUCT_OUTLIERS_CHANGED_PRODUCTS_EXPORT,
  PRODUCT_OUTLIERS_HIDE_SUGGESTIONS,
  PRODUCT_OUTLIERS_QA_CHANGES,
  PRODUCT_OUTLIERS_REJECT_CHANGES,
  PRODUCT_OUTLIERS_SIMILARITY_SCORES,
  PRODUCTS_BY_GLOBAL_PRODUCT_IDS,
  PRODUCTS_WITHOUT_IMAGES,
  PUBLIC_UPLOAD_FILE,
  PRESIGNED_URL,
  RESET_PRODUCT_THUMBNAILS,
  RESOLVE_CONFLICT_BY_NEW_GLOBAL_PRODUCT,
  ROLE,
  ROLES,
  SEPARATE_HILLCREST_PRODUCTS,
  SIMILAR_BRANDS,
  SIMILAR_MANUFACTURERS,
  SKU_MAPPINGS_CANCEL,
  SKU_MAPPINGS_CONVERT_MANUFACTURER_PRODUCTS_OF_PRODUCT_LIST,
  SKU_MAPPINGS_CONVERT_PRODUCTS,
  SKU_MAPPINGS_CREATE_MANUFACTURER_PRODUCTS,
  SKU_MAPPINGS_DELETE_PRODUCTS,
  SKU_MAPPINGS_DELETE_UNVERIFIED_MANUFACTURER_PRODUCTS,
  SKU_MAPPINGS_FSA_UPDATE,
  SKU_MAPPINGS_MERGE_PRODUCTS,
  SKU_MAPPINGS_UNLINK_ALL_PRODUCTS,
  SKU_MAPPINGS_UNLINK_PRODUCTS,
  SKU_MAPPINGS_UNLINK_PRODUCT_GROUP,
  SKU_MAPPINGS_UNVERIFY_LINKS,
  SKU_MAPPINGS_VERIFY_LINKS,
  SKU_MAPPINGS_VERIFY_PRODUCTS,
  SKU_MAPPINGS_VERIFY,
  SKU_MAPPINGS,
  STAT,
  CLASSIFICATION_STAT,
  CLASSIFICATION_STATS,
  MFR_PRODUCT_CLASSIFICATION_STAT,
  VENDOR_PRODUCT_CLASSIFICATION_STAT,
  MFR_PRODUCT_VERIFIED_STAT,
  VENDOR_PRODUCT_VERIFIED_STAT,
  TAXONOMY,
  TAXONOMY_CATEGORY_DETAILS,
  TAXONOMY_CHILD_CATEGORY_DETAILS,
  TAXONOMY_CATEGORY_LEVEL_OPTIONS,
  TAXONOMY_CATEGORY_NAMES,
  TAXONOMY_CATEGORY_OPTIONS,
  TAXONOMY_CATEGORY_OPTIONS_FOR_PRODUCT_LIST,
  TAXONOMY_CREATE_CATEGORY,
  TAXONOMY_DELETE_CATEGORY,
  TAXONOMY_EDIT_CATEGORY_NAME,
  TAXONOMY_EXPORT,
  TAXONOMY_GET,
  TAXONOMY_L4_DEFAULT_IMAGE,
  TAXONOMY_SET_L4_DEFAULT_IMAGE,
  TAXONOMY_SET_L4_DEFAULT_IMAGE_NOT_APPLICABLE,
  TAXONOMY_GET_L4_CATEGORIES_BY_IMAGE_UPLOAD_STATUS,
  TAXONOMY_GET_L3_CATEGORIES_BY_L4_IMAGE_UPLOAD_STATUS,
  TAXONOMY_GET_REJECTED_L4_IMAGES_BY_USER_ID,
  TAXONOMY_REVIEW_L4_DEFAULT_IMAGES,
  TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS,
  TAXONOMY_UPPER_LEVELS,
  TOKEN_CONFIG,
  UPDATE_MANUFACTURER_PRODUCT_SCORES,
  UPDATE_SYSCO_STATUSES,
  UPDATE_USER_ROLES,
  UPDATE_VENDOR_PRODUCT_SCORES,
  UPLOAD_FILE,
  USERS,
  USERS_GET_FOR_DROPDOWN,
  USERS_OF_REJECTED_DEFAULT_L4_IMAGES,
  VIEWS,
  WEB_IMAGE_RECOMMENDATIONS,
  TASKS,
  TASKS_COUNT_BY_STATUS,
  SQS_ATTRIBUTE_MESSAGE_COUNT,
  SCHEMA,
  DELETE_SCHEMA,
  SCHEMAS,
  SCHEMA_ATTRIBUTE,
  ATTRIBUTE_SCHEMA_IMPORT,
  SCHEMA_ATTRIBUTES,
  SCHEMA_ATTRIBUTES_DELETE,
  SCHEMA_ATTRIBUTES_EXPORT,
  GLOBAL_PRODUCT_MANUFACTURER_PDP,
  GLOBAL_PRODUCT_MANUFACTURERS,
  GLOBAL_PRODUCT_MANUFACTURERS_BY_SEARCH,
  GLOBAL_PRODUCT_MANUFACTURER_UPDATE,
  USER_CREATE,
  isProd,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Tab, Tabs } from 'react-bootstrap';

import APIIntegrationDropDown from 'components/manage_vendors/vendor_details/APIIntegration/APIIntegrationDropDown';
import { backgroundGrey4 } from 'lib/css/colors';
import ImageFlagDropdown from 'components/manage_vendors/vendor_details/ImageFlagDropdown';
import VendorCatalogSettingsView from 'components/manage_vendors/vendor_details/catalog_settings/VendorCatalogSettingsView';
import VendorRow from 'components/manage_vendors/vendor_details/VendorRow';

const vendorRows = {
  name: 'Vendor Name',
  vendor_url: 'Vendor Website',
  excluded_p0_sync_attributes: 'Attributes excluded from P0 sync',
  excluded_cnd_sync_fields: 'Fields excluded from CND to FSA sync',
  rejected_brand_names: 'Rejected Brand Names',
  chains: 'Chains',
  vendor_category: 'Select Vendor Category',
  archived: 'Archived',
  p0_sync_enabled: 'P0 sync is enabled',
  is_proprietary_images: 'Is proprietary images',
  cnd_image_sync_enabled: 'Cut and Dry image sync enabled',
  is_dp_partner: 'Is a distributor portal partner',
  is_currently_onboarding: 'Is the dp partner currently being onboarded',
  is_ready_for_classification: 'Ready For Classification',
  is_a_trusted_source: 'Trusted source for product data',
  override_from_manufacturer_product: 'override data by manufacturer product',
  use_rosetta_categories: 'Use Rosetta Categories',
  use_default_l4_images: 'use default l4 category images',
  populate_tag_family_data: 'Populate tag family data at P0 sync',
  is_test_vendor: 'Is Test Vendor',
  sync_unverified_brands: 'Sync Unverified Brands',
  index_attributes: 'Index Attributes',
  link_products_on_chain: 'Link products on the chains',
};

const VENDOR_ENRICH_TABS = {
  GENERAL_INFO: 'General Info',
  SETTINGS: 'Settings',
  CATALOG_SETTINGS: 'Catalog Settings',
};

function VendorDetailSplitView({ vendor, refetch }) {
  const [activeTabKey, setActiveTabKey] = useState(VENDOR_ENRICH_TABS.GENERAL_INFO);

  return (
    <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
      <Tab eventKey={VENDOR_ENRICH_TABS.GENERAL_INFO} title={VENDOR_ENRICH_TABS.GENERAL_INFO}>
        <div className="px-5 mt-3">
          {Object.keys(vendorRows).map(key => (
            <div key={key}>
              <VendorRow label={key} title={vendorRows[key]} vendor={vendor} refetch={refetch} />
              <hr className={css(styles.separator)} />
            </div>
          ))}
        </div>
      </Tab>
      <Tab eventKey={VENDOR_ENRICH_TABS.SETTINGS} title={VENDOR_ENRICH_TABS.SETTINGS}>
        <div className="px-3 mt-3">
          <APIIntegrationDropDown globalVendorId={vendor.id} />
        </div>
        <div className="px-3 mt-3">
          <ImageFlagDropdown imageFlag={vendor.image_flag} />
        </div>
      </Tab>
      <Tab
        eventKey={VENDOR_ENRICH_TABS.CATALOG_SETTINGS}
        title={VENDOR_ENRICH_TABS.CATALOG_SETTINGS}
      >
        <div className="px-3 mt-3">
          <VendorCatalogSettingsView globalVendorId={vendor.id} />
        </div>
      </Tab>
    </Tabs>
  );
}

const styles = StyleSheet.create({
  separator: {
    color: backgroundGrey4,
    backgroundColor: backgroundGrey4,
    margin: '6px 0',
  },
});

VendorDetailSplitView.propTypes = {
  vendor: PropTypes.object,
  refetch: PropTypes.func,
};

export default VendorDetailSplitView;
